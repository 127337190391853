@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Noto+Sans:wght@300;400&family=PT+Serif&display=swap');

body {
  color: #393939;
}

h1, h2, h3, h4, h5, p, span, li, div {
  color: #393939;
}

h3 {
  font-size: 18px;
  padding-bottom: 8px;
}

.logo {
  font-family: Arvo, sans-serif;
  font-size: 22px;;
}